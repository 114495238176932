<!-- eslint-disable max-len -->
<template>
  <svg viewBox="0 0 674 674" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M310.228 236.986C310.228 187.694 289.927 105 153.955 105H0L115.918 304.178H12.5216V569H84.0475V368.967H153.558L268.745 569H349L226.945 358.33C280.875 339.923 310.228 297.531 310.228 236.986ZM192.856 299.904L116.642 169.794H153.955C228.649 169.794 238.723 203.753 238.723 236.986C238.723 272.542 224.87 291.88 192.856 299.904Z"
      fill="#36599C"
    />
    <path
      d="M510.533 569H670V304.57H545.417V369.43H605.217V504.14H510.533C469.25 504.14 435.783 470.633 435.783 429.301V244.699C435.783 203.367 469.25 169.86 510.533 169.86H670V105H510.533C433.471 105 371 167.545 371 244.699V429.301C371 506.455 433.471 569 510.533 569Z"
      fill="#36599C"
    />
  </svg>
</template>
